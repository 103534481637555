import 'bootstrap/dist/css/bootstrap.css';
import '../scss/styles.scss';
import 'bootstrap/dist/js/bootstrap.js';


//  toggle the side navigation on the thinkSupport page on small devices
window.addEventListener("DOMContentLoaded", () => {
    const sidebarToggle = document.body.querySelector("#sidebarToggle");
    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", (event) => {
        event.preventDefault();
        document.body.classList.toggle("thinksupport--sidebar-toggled");
      });
    }
  });

document.addEventListener('DOMContentLoaded', () => {

    document.querySelectorAll('a[href="#download-limit-reached"]').forEach((tag) => {
        tag.removeAttribute("href");
        tag.addEventListener("onclick", (e) => {
            e.preventDefault();
        })
        tag.classList.add("disabled");
    })

});